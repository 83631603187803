//App.js
import { useRef, useEffect } from "react";
import { Container } from "react-bootstrap";
import Header from "./components/Header";
import StakeForm from "./components/StakeForm";
import logo from './elmo-stakeing-logo2.png';
import { useAccount, useDisconnect } from 'wagmi';
import FOG from 'vanta/dist/vanta.fog.min.js';


function App() {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  const vantaEffect = useRef(null)
  const myRef = useRef();

  useEffect(() => {
    if (myRef.current) {
      let y = myRef.current.scrollHeight;

      if (!vantaEffect.current) {
        vantaEffect.current = FOG({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: y,
          minWidth: 200.00,
          highlightColor: 0xff8c6e,
          midtoneColor: 0xf78383,
          lowlightColor: 0xffdcdc,
          baseColor: 0xff4141,
          blurFactor: 0.60,
          zoom: 1.10
        })
      }

      return () => {
        if (vantaEffect.current) {
          vantaEffect.current.destroy()
          vantaEffect.current = null
        }
      }
    }
  }, [myRef]);

  useEffect(() => {
    if (!isConnected) {
      // Connection to the Ethereum provider is lost
      disconnect();
    }
  }, [address, isConnected, disconnect]);

  return (
    <>
    <Header />
    <section id="entire-staking-section" className="section section--pb0 section--first" style={{height: '100vh', overflowY: 'auto'}} ref={myRef}>
      <Container>
        <StakeForm />
      </Container>
      <img className="stakingMofo" src={logo} alt="ElmoStake Logo" />
    </section>
    </>
  );
}

export default App;
