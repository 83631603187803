//Header.js
import React from 'react';
import logo from '../elmoerc.png';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const Header = () => {

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__content">
                <div className="header__logo">
                  <img src={logo} alt="ElmoStake Logo" />
                  <ul className="header__nav" id="header__nav">
                    <li>
                      <a href="https://www.elmoerc.io/">Home</a>
                    </li>
                    <li>
                      <a target="_blank" href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x335F4e66B9B61CEE5CeaDE4e727FCEC20156B2F0">Buy $ELMO</a>
                    </li>
                  </ul>
                </div>
                <ConnectButton chainStatus="none" showBalance={false} />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;