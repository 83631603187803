import React, { useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import DateTimeDisplay from './DateTimeDisplay';
import Countdown from "react-countdown";
import moment from "moment/moment";
import Spinner from "react-bootstrap/Spinner";
import { useAccount, useWalletClient } from "wagmi";
import Web3 from "web3/dist/web3.min.js";
import Swal from "sweetalert2";
import StakingContract from "../contractABI/StakingContract.json";
import ElmoContract from "../contractABI/ElmoContract.json";

const contractAddress = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS;
const elmoContractAddress = process.env.REACT_APP_ELMO_CONTRACT_ADDRESS;
const decimals = 18;

const StakeForm = () => {
  const { data: signer } = useWalletClient();
  const { address, isConnected } = useAccount();
  const [provider, setProvider] = useState("");
  const [stakerDetails, setStakerDetails] = useState({});
  const [stakePeriod, setStakePeriod] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalBurned, setTotalBurned] = useState(0);
  const [totalPool, setTotalPool] = useState(0);
  const [emergencyWithdrawFee, setEmergencyWithdrawalPenalty] = useState(0);
  const [amount, setAmount] = useState("");
  const [stakeLoading, setStakeLoading] = useState(false);
  const [approveloading, setApproveLoading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [allowance, setAllowance] = useState("");
  const [balance, setBalance] = useState("");
  const [countDownCompleted, setCountDownCompleted] = useState(false);
  const [isApproved, setIsApproved] = useState(false);

  const showAlert = (title, text, icon) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showConfirmButton: false,
        timer: 3500
      });
  }

  const showLoading = (title) => {
    Swal.fire({
        title: title,
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading()
        },
      });
  }

  const getStakeDetails = async () => {
    setLoadingState(true);
    if (provider) {
      const web3 = new Web3(provider);
      window.contract = new web3.eth.Contract(
        StakingContract,
        contractAddress
      );
      let staker = await window.contract.methods.getStakeDetails(address).call();
      let period = await window.contract.methods.STAKE_PERIOD().call();
  
      setStakePeriod(period);
      setStakerDetails(staker);
      setLoadingState(false);
    }
  };

  const getCurrentBalance = async () => {
    setLoadingState(true);
    if (provider) {
      const web3 = new Web3(provider);
      window.mint_contract = new web3.eth.Contract(
        ElmoContract,
        elmoContractAddress
      );
      let balanceToken = await window.mint_contract.methods
        .balanceOf(address)
        .call();
      let Allowance = await window.mint_contract.methods
        .allowance(address, contractAddress)
        .call();
      setBalance(balanceToken);
      
      setAllowance(Allowance);
      setLoadingState(false);
    }
  };

  const validation = () => {
    let error = false;
    if (amount === "" || amount === undefined || amount == null || amount === 0) {
      if (amount === 0) {
        showAlert('Error', 'Please input amount greater than 0', 'error')
      } else {
        showAlert('Error', 'Please input amount', 'error')
      }
      error = true;
    }
    if (address === "" || address === undefined || address == null) {
      showAlert('Error', 'Please connect wallet!', 'error')
      error = true;
    }

    return error;
  };

  const stakeElmo = async () => {
    try {
      const web3 = new Web3(provider);
      window.staking_contract = new web3.eth.Contract(
        StakingContract,
        contractAddress
      );
  
      if (validation() === false) {
        if(amount > convert(balance)) {
          showAlert('Error', 'You try to stake more than your balance!', 'error')
          return;
        }
        if(amount > convert(allowance)) {
          showAlert('Error', 'You try to stake more than you approved ('+ convert(allowance).toLocaleString() +' $ELMO)!', 'error')
          return;
        }
        setStakeLoading(true)
        showLoading('Staking')
        let decimalAmount = web3.utils.toBN(amount).mul(web3.utils.toBN(10).pow(web3.utils.toBN(decimals)));
        await window.staking_contract.methods
          .stake(decimalAmount)
          .send({ from: address })
          .on("transactionHash", (hash) => {
            console.log('Transaction hash:', hash);
          })
          .on('confirmation', async (confirmationNumber, receipt) => {
            if (confirmationNumber === 1 && receipt.status) {
              Swal.close()
              showAlert('Success', 'Your $ELMO was staked successfully!', 'success')
              setInterval(() => {
                window.location.reload(true);
              }, 3000);
              await getStakeDetails();
              await getCurrentBalance();
              setStakeLoading(false);
            }
          })
          .on("error", (error) => {
            console.log(error)
            Swal.close()
            if (error.code === 4001) {
              showAlert('Error', 'User rejected the request', 'error')
              setStakeLoading(false);
            } else {
              showAlert('Error', 'Something went wrong while approving', 'error')
              setStakeLoading(false);
            }
          });
      }
    } catch (err) {
      console.log(err);
      showAlert('Error', 'An unexpected error occurred', 'error')
      setStakeLoading(false);
    }
  };

  const unstakeElmo = async () => {
    try {
      if (provider) {
        showLoading('Withdrawing')
        const web3 = new Web3(provider);
        window.staking_contract = new web3.eth.Contract(
          StakingContract,
          contractAddress
        );
        await window.staking_contract.methods
          .unstake()
          .send({ from: address })
          .on("confirmation", async (confirmationNumber, receipt) => {
            if (confirmationNumber === 1 && receipt.status) {
              Swal.close()
              setIsApproved(false);
              showAlert('Success', 'Your $ELMO withdraw was successful!', 'success')
  
              // Call getStakeDetails and getCurrentBalance here instead of reloading the page
              await getStakeDetails();
              await getCurrentBalance();
            }
          })
          .on("error", (error) => {
            console.log(error)
            Swal.close()
            if (error.code === 4001) {
              showAlert('Error', 'User rejected the request', 'error')
            } else {
              showAlert('Error', 'Something went wrong during unstaking', 'error')
            }
          });
      }
    } catch(err) {
      console.log(err);
      Swal.close()
      showAlert('Error', 'An unexpected error occurred', 'error')
    }
  };

  const emergencyUnstakeElmo = async () => {
    try {
      if (provider) {
        showLoading('Emergency Withdrawing')
        const web3 = new Web3(provider);
        window.staking_contract = new web3.eth.Contract(
          StakingContract,
          contractAddress
        );
        await window.staking_contract.methods
          .emergencyUnstake()
          .send({ from: address })
          .on("confirmation", async (confirmationNumber, receipt) => {
            if (confirmationNumber === 1 && receipt.status) {
              Swal.close()
              setIsApproved(false);
              showAlert('Success', 'Your emergency $ELMO unstake was successful!', 'success')
  
              // Call getStakeDetails and getCurrentBalance here instead of reloading the page
              await getStakeDetails();
              await getCurrentBalance();
            }
          })
          .on("error", (error) => {
            console.log(error)
            Swal.close()
            if (error.code === 4001) {
              showAlert('Error', 'User rejected the request', 'error')
            } else {
              showAlert('Error', 'Something went wrong during unstaking', 'error')
            }
          });
      }
    } catch(err) {
      console.log(err);
      Swal.close()
      showAlert('Error', 'An unexpected error occurred', 'error')
    }
  };

  const approve = async () => {
    try {
        setApproveLoading(true);
        showLoading('Approving')
        if (provider) {
            const web3 = new Web3(provider);
            window.mint_contract = new web3.eth.Contract(
              ElmoContract,
              elmoContractAddress
            );
            await window.mint_contract.methods
              .approve(contractAddress, balance)
              .send({ from: address })
              .on("confirmation", async (confirmationNumber, receipt) => {
                if (confirmationNumber === 1 && receipt.status) {
                  Swal.close()
                  setApproveLoading(false);
                  setIsApproved(true); // Add this line to set approval state to true
                }
              })
              .on("error", (error) => {
                console.log(error);
                Swal.close()
                if (error.code === 4001) {
                  showAlert('Error', 'User rejected the request', 'error')
                } else {
                  showAlert('Error', 'Something went wrong while approving', 'error')
                }
                setApproveLoading(false);
              });
          }
    } catch(err) {
        console.log(err);
        Swal.close()
        showAlert('Error', 'An unexpected error occurred', 'error')
        setApproveLoading(false);
    }
  };

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-danger me-3'
    },
    buttonsStyling: false
  })

  const handleEmercency = () => {
    let fee = emergencyWithdrawFee.toString();
    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! You will lose " + fee + "% of your entire staked $ELMO!!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, withdraw!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        emergencyUnstakeElmo();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire({
          title: 'Cancelled',
          text: 'Your emergency withdraw was cancelled :)',
          icon: 'error',
          showConfirmButton: false,
          timer: 3500
        });
      }
    })
  }

  const convert = (number) => {
    return Math.ceil(Math.round((number / (10*10**(decimals-3))))/100)
  }

  const getPublicData = async () => {
    const web3 = new Web3(new Web3.providers.WebsocketProvider(process.env.REACT_APP_INFURA_URL));
    const stakeingDetails = new web3.eth.Contract(StakingContract, contractAddress);
    const staked_total = await stakeingDetails.methods.totalStaked().call()
    const pool_total = await stakeingDetails.methods.getRemainingStakePool().call()
    const burned_total = await stakeingDetails.methods.getTotalBurned().call()
    const emergency_fee = await stakeingDetails.methods.emergencyWithdrawalPenalty().call()
    setTotalStaked(staked_total)
    setTotalPool(pool_total)
    setTotalBurned(burned_total)
    setEmergencyWithdrawalPenalty(emergency_fee)
  }

  useEffect(() => {
    getPublicData()
    const interval = setInterval(() => {
      getPublicData()
    }, 30000);
    return () => clearInterval(interval);
  })

  useEffect(() => {
    if (signer) {
      setProvider(signer);
    }
  }, [signer]);

  useEffect(() => {
    getStakeDetails();
  }, [provider]);

  useEffect(() => {
    getCurrentBalance();
  }, [provider]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <>
        <p className='message-stakeing mb-0'>Congratutalions Elmofo, you made it!</p>
        <p className='message-stakeing'>You can now withdraw your $ELMO including rewards!</p>
        </>
      );
    } else {
      // Render a countdown
      return (
        <>
        <p className='message-stakeing'>Stake period ends in:</p>
        <div className="show-counter">
          <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 2} />
          <p>:</p>
          <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
          <p>:</p>
          <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
        </div>
        </>
      );
    }
  };

  return (
    <>
    <Row>
      <Col className='col-12 col-lg-7 btm'>
        <div className='staking'>
          <div className='staking__info'>
              <h2>$ELMO Steak</h2>
              <p className='elmoBlack'>Stake your $ELMO for 43 days and earn 3% and burn 7%!</p>
              <br />
              <p className='fontSmall'>
                Once you stake your tokens are locked for 43 days! You can 'EMERGENCY WITHDRAWAL', but this will cost {emergencyWithdrawFee}% of your staked $ELMO!
              </p>
          </div>
          {loadingState && isConnected ? (
            <div className="loader-wrap">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            <>
            {isConnected ? (
              <>
                <div className='staking__group'>
                  <div className='stake_info'>
                    <span className="staking__label">
                      Staked<br /> {Number(convert(stakerDetails.stakedAmount)).toLocaleString()} $ELMO
                    </span>
                    <span className='staking__label'>
                      Burned<br /> {Number(convert(stakerDetails.burnedAmount)).toLocaleString()} $ELMO
                    </span>
                    <span className='staking__label'>
                      Reward<br /> {Number(convert(stakerDetails.rewardAmount)).toLocaleString()} $ELMO
                    </span>
                  </div>
                </div>
                {(!stakerDetails.stakeTime || stakerDetails.stakeTime === "0") ? (
                  <>
                  <div className='staking__group top-40'>
                    <label htmlFor="stake" className="staking__label_1">
                      Balance: {Number(convert(balance)).toLocaleString()} $ELMO
                      {allowance > 0 ? (<><br />Approved: {Number(convert(allowance)).toLocaleString()} $ELMO</>) : ('')}
                    </label>
                    <input 
                        id="stake1" 
                        type="text" 
                        name="stake1" 
                        className="staking__input" 
                        placeholder="0.00"
                        value={amount}
                        onChange={(e) => {
                          const regex = /[^0-9]/g;
                          const value = e.target.value;
                          const newValue = value.replace(regex, "");
                          setAmount(newValue)}} />
                    {isApproved || allowance > 0 ? (
                      <>
                        <Button className="staking__btn" disabled={!amount || stakeLoading} onClick={stakeElmo}>Stake</Button>
                      </>
                      ) : (
                      <>
                        <Button className="staking__btn" disabled={!amount || approveloading} onClick={approve}>Approve</Button>
                      </>
                    )}      
                  </div>
                  </>
                ) : (
                  <>
                  <div className='staking__group top-40'>
                  {(stakerDetails.stakeTime && stakerDetails.stakeTime !== "0") ? (
                      <>
                      {
                        stakerDetails.stakeTime !== "0" && stakerDetails.stakeTime !== null &&
                        <Countdown 
                          date={moment(stakerDetails.stakeTime * 1000).add(stakePeriod, "seconds").toDate().getTime()} 
                          renderer={renderer} 
                          onComplete={e => { setCountDownCompleted(true) }} 
                        />
                      }
                      {!countDownCompleted ?
                        <>
                        <Button className="staking__btn" onClick={handleEmercency}>Emergency withdrawal ({emergencyWithdrawFee}% Fee)</Button>
                        </>
                        : 
                        <>
                        <Button className="staking__btn" onClick={unstakeElmo}>Withdraw</Button>
                        </>
                      }
                      </>
                    ) : (
                      <div className="loader-wrap">
                        <Spinner animation="border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </Spinner>
                      </div>
                    )}
                  </div>
                  </>
                )}
              </>
            ) : (
              <div className="loader-wrap not-connected">
                <p className='message-stakeing'>Connect your wallet!</p>
              </div>
            )}
            </>
          )}
        </div>
      </Col>
      <Col className='col-12 col-lg-5'>
        <div className='staking'>
        <div className='stake_info_general'>
          <div className="staking__label_second">
            <div className='amount'>{Number(convert(totalPool)).toLocaleString()} $ELMO</div>
            <div className='label'>Total available in staking pool</div>
          </div>
          <div className='staking__label_second'>
            <div className='amount'>{Number(convert(totalStaked)).toLocaleString()} $ELMO</div>
            <div className='label'>Total staked</div>
          </div>
          <div className='staking__label_second'>
            <div className='amount'>{Number(convert(totalBurned)).toLocaleString()} $ELMO</div>
            <div className='label'>Total burned by staking</div>
          </div>
        </div>
        </div>
      </Col>
    </Row>
    </>
  );
};

export default StakeForm;
